<template>
  <div>
    <div class="w-100 text-center" v-if="isLoading">
      <b-spinner class="align-middle"></b-spinner>
      <strong>Loading...</strong>
    </div>

    <template v-else>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="mb-2"
          @click="cleanUpForm()"
          v-b-modal.form-schedule
          >Add Schedule</b-button
        >
      </div>
      <b-row>
        <b-col cols="12" md="4" v-for="(items, key) in scheduleData" :key="key">
          <b-card>
            <h4 class="text-center mb-2">
              {{ moment(`${key}-01`).locale("id").format("MMMM YYYY") }}
            </h4>
            <table class="table table-striped table-sm">
              <tbody>
                <tr v-for="item in items" :key="item.uuid">
                  <td>{{ item.date }}</td>
                  <td>{{ item.start_time }} - {{ item.end_time }}</td>
                  <td class="d-flex align-items-center" style="gap: 5px">
                    <b-button
                      size="sm"
                      style="padding: 2px"
                      variant="warning"
                      @click="editItem(item)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button
                      size="sm"
                      style="padding: 2px"
                      variant="danger"
                      @click="deleteItem(item.uuid)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </b-col>
        <b-col cols="12">
          <a
            @click="handleBackButton()"
            variant="secondary"
            class="btn waves-effect waves-float waves-light btn-secondary"
          >
            Kembali
          </a>
        </b-col>
      </b-row>
      <b-modal
        id="form-schedule"
        centered
        :title="editId !== null ? 'Edit Schedule' : 'Add Schedule'"
        size="lg"
        no-close-on-backdrop
      >
        <ValidationObserver>
          <div class="form">
            <div class="row mb-2">
              <b-col cols="12">
                <div class="form-group">
                  <label for="date">Date:</label>
                  <flat-pickr
                    id="date"
                    v-model="formPayload.date"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d' }"
                  />
                  <small
                    v-for="(validation, index) in validations.date"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
              </b-col>
              <b-col cols="12">
                <div class="form-group">
                  <label for="start_time">Start Time:</label>
                  <flat-pickr
                    id="start_time"
                    v-model="formPayload.start_time"
                    class="form-control"
                    :config="{
                      dateFormat: 'H:i',
                      enableTime: true,
                      noCalendar: true,
                    }"
                  />
                  <small
                    v-for="(validation, index) in validations.start_time"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
              </b-col>
              <b-col cols="12">
                <div class="form-group">
                  <label for="end_time">End Time:</label>
                  <flat-pickr
                    id="end_time"
                    v-model="formPayload.end_time"
                    class="form-control"
                    :config="{
                      dateFormat: 'H:i',
                      enableTime: true,
                      noCalendar: true,
                    }"
                  />
                  <small
                    v-for="(validation, index) in validations.end_time"
                    :key="`errorName${index}`"
                    class="text-danger"
                    >{{ validation }}</small
                  >
                </div>
              </b-col>
            </div>
          </div>
        </ValidationObserver>
        <template #modal-footer="{}">
          <b-button
            variant="primary"
            @click="editId !== null ? updateItem() : createItem()"
            v-bind:disabled="isLoading"
          >
            Save
          </b-button>
        </template>
      </b-modal>
    </template>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { required } from "@validations";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});
import {
  BCard,
  BFormGroup,
  BFormRadio,
  BButton,
  BRow,
  BSpinner,
  BCol,
  BFormFile,
  BFormCheckbox,
  BBadge,
  VBModal,
} from "bootstrap-vue";
import { successNotification, errorNotification } from "@/auth/utils";
export default {
  components: {
    BCard,
    BRow,
    BSpinner,
    BCol,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BFormFile,
    BButton,
    BBadge,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      required,
      moment,
      formPayload: {},
      isLoading: false,
      validations: "",
      scheduleData: [],
      editId: null,
      validations: "",
    };
  },

  setup() {
    return {
      successNotification,
      errorNotification,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        date: "",
        start_time: "",
        end_time: "",
      };
    },
    editItem(item) {
      this.cleanUpForm();
      this.editId = item.uuid;
      this.formPayload = {
        date: item.date,
        start_time: item.start_time,
        end_time: item.end_time,
      };
      this.$bvModal.show("form-schedule");
    },
    updateItem() {
      this.isLoading = true;
      let topicId = this.$route.params.uuid;
      this.$http
        .post(
          `/api/v1/admin/mentorship/schedules/${topicId}/${this.editId}`,
          this.formPayload
        )
        .then((response) => {
          this.$bvModal.hide("form-schedule");
          this.getData();
          successNotification(
            this,
            "Success",
            "Schedule successfully updated!"
          );
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    createItem() {
      this.isLoading = true;
      let topicId = this.$route.params.uuid;
      this.$http
        .post(`/api/v1/admin/mentorship/schedules/${topicId}`, this.formPayload)
        .then((response) => {
          this.$bvModal.hide("form-schedule");
          this.getData(this.currentPage);
          successNotification(this, "Success", "Schedule successfully created");
          this.cleanUpForm();
          this.isLoading = false;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.messages
          ) {
            errorNotification(this, "Error", error.response.data.meta.messages);
          } else if (
            error.response &&
            error.response.data &&
            error.response.data.meta &&
            error.response.data.meta.validations
          ) {
            this.validations = error.response.data.meta.validations;
            errorNotification(
              this,
              "Error",
              "Form Tidak Boleh ada yang kosong"
            );
          }
          this.isLoading = false;
        });
    },
    getData() {
      let idTopic = this.$route.params.uuid;
      this.isLoading = true;
      this.$http
        .get(`/api/v1/admin/mentorship/schedules/${idTopic}`)
        .then((response) => {
          this.scheduleData = response.data.data.reduce((acc, curr) => {
            const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
            const [, year, month, day] = curr.date.match(regex);
            const key = `${year}-${month}`;
            const startTime = this.formatTime(curr.start_time);
            const endTime = this.formatTime(curr.end_time);
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push({
              ...curr,
              start_time: startTime,
              end_time: endTime,
            });
            return acc;
          }, {});
          console.log(this.scheduleData);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },

    deleteItem(id) {
      let idMentor = this.$route.params.uuid;
      this.$swal({
        title: "Are you sure?",
        text: "Are you sure to delete this Topic?",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.isLoading = true;
          this.$http
            .delete(`/api/v1/admin/mentorship/schedules/${idMentor}/${id}`)
            .then((response) => {
              this.getData();
              this.$swal({
                icon: "success",
                title: "Success!",
                text: "Topic successfully deleted",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.isLoading = false;
            })
            .catch((error) => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(
                  this,
                  "Oops!",
                  error.response.data.meta.messages
                );
              }
              this.isLoading = false;
            });
        }
      });
    },

    formatTime(timeString) {
      const [hours, minutes] = timeString.split(":");
      return `${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
